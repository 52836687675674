@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

$theme: #ffdddd;

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
}

.form-group {
  margin: 10px;
  input {
    margin-left: 0;
  }
}

button {
  font-family: 'Nunito', sans-serif;
}

#options_panel {
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  background-color: lighten($theme, 2);
  height: 100%;
  width: calc(max(25%, 200px));
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0);
  transition: transform 0.3s;
  padding: 10px;
  &.visible {
    transform: translateX(0);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
  #options_button {
    &::before {
      content: "☰";
    }
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0);
    position: absolute;
    right: 0;
    transform: translateX(100%) translateY(-10px);
    box-shadow: none;
    border: none;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    height: 25px;
    width: 25px;
    font-size: 40px;
  }
}

#chord_display {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translateY(calc(25vh - 50%));
  @media only screen and (max-width: 600px) {
    font-size: 6rem;
  }
  font-size: 10rem;
}

#next_chord {
  position: absolute;
  width: 100%;
  top: 50%;
  // border: 5px inset;
  border: none;
  border-radius: 0;
  height:50%;
  background-color: $theme;
  text-transform: uppercase;
  font-size: 5rem;
  @media only screen and (max-width: 600px) {
    font-size: 4rem;
  }
  &:active {
    background-color: darken($theme, 0.2);
  }
}
